import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { breakpoints, hexToRGBA } from '@ecommerce/shared'

const cssPrefix = 'OrderLineCardSkeleton__'
const pfx = (className: string) => `${cssPrefix}${className}`

const TextSkelleton = styled.div<{ height: number; maxWidthPercent: number; minWidthPercent: number }>`
  background: ${(props) => props.theme.colors.grey};
  height: ${(props) => props.height}px;
  width: ${(props) => Math.random() * (props.maxWidthPercent - props.minWidthPercent) + props.minWidthPercent}%;
`

const getTextSkelleton = (height: number, maxWidthPercent: number, minWidthPercent: number) => (
  <TextSkelleton height={height} maxWidthPercent={maxWidthPercent} minWidthPercent={minWidthPercent} />
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 10px 20px 0px;
  border-top: 2px solid ${(props) => hexToRGBA(props.theme.colors.black20, 0.1)};
  .${cssPrefix} {
    &image {
      &-container {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => props.theme.colors.grey};
      }
    }
    &info {
      &-container {
        padding-left: 10px;
        flex-grow: 1;
        max-width: calc(100% - 80px);
        font-size: 12px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.platinum80};
      }
      &-name {
        height: 35px;
        max-width: 160px;
        overflow: hidden;
      }
      &-discount {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 20px;
        &-percent {
          width: 100px;
          color: ${(props) => props.theme.colors.green};
        }
        &-original-price {
          text-decoration: line-through;
          font-weight: lighter;
          width: 80px;
        }
      }
      &-totals {
        display: flex;
        justify-content: space-between;
        height: 25px;
        align-items: flex-end;
        &-count {
          width: 100px;
        }
        &-amount {
          width: 80px;
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &info {
        &-name {
          max-width: initial;
        }
      }
    }
  }
`

export const OrderLineItemSkeleton = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <Wrapper {...props}>
      <div className={pfx('image-container')} />
      <div className={pfx('info-container')}>
        <div className={pfx('info-name')}>{getTextSkelleton(12, 30, 50)}</div>
        {Math.random() >= 0.7 ? (
          <div className={pfx('info-discount')}>
            <div className={pfx('info-discount-percent')}>{getTextSkelleton(12, 80, 100)}</div>
            <div className={pfx('info-discount-original-price')}>{getTextSkelleton(12, 100, 100)}</div>
          </div>
        ) : (
          <div className={pfx('info-discount')} />
        )}
        <div className={pfx('info-totals')}>
          <div className={pfx('info-totals-count')}>{getTextSkelleton(12, 80, 100)}</div>
          <div className={pfx('info-totals-amount')}>{getTextSkelleton(12, 100, 100)}</div>
        </div>
      </div>
    </Wrapper>
  )
}
