import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { CLLineItem, Image, useLocation, breakpoints, getDiscountPercentage, hexToRGBA } from '@ecommerce/shared'

const cssPrefix = 'OrderLineCard__'
const pfx = (className: string) => `${cssPrefix}${className}`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 10px 20px 0px;
  border-top: 2px solid ${(props) => hexToRGBA(props.theme.colors.black20, 0.1)};
  .${cssPrefix} {
    &image {
      &-container {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &info {
      &-container {
        padding-left: 10px;
        flex-grow: 1;
        max-width: calc(100% - 80px);
        font-size: 12px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.platinum80};
      }
      &-name {
        height: 35px;
        max-width: 160px;
        overflow: hidden;
        span {
          line-height: 1.4;
        }
      }
      &-discount {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 20px;
        &-percent {
          color: ${(props) => props.theme.colors.green};
        }
        &-original-price {
          text-decoration: line-through;
          font-weight: lighter;
        }
      }
      &-totals {
        display: flex;
        justify-content: space-between;
        height: 25px;
        align-items: flex-end;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &info {
        &-name {
          max-width: initial;
        }
      }
    }
  }
`

interface OrderLineCardProps extends HTMLAttributes<HTMLDivElement> {
  orderLine: CLLineItem
}

const formatQuantity = (quantity: number) => {
  if (!quantity) return ''
  if (quantity === 1) return `${quantity} unidad`
  return `${quantity} unidades`
}

export const OrderLineItem = ({ orderLine, ...restProps }: OrderLineCardProps) => {
  const { image_url, name, quantity, total_amount_cents, metadata, unit_amount_cents } = orderLine.attributes
  const discountPercentage = getDiscountPercentage(unit_amount_cents, metadata?.originalPrice)
  const { toCurrency } = useLocation()
  return (
    <Wrapper {...restProps}>
      <div className={pfx('image-container')}>
        {image_url ? <Image src={image_url} alt={name} className={pfx('image')} /> : <div className={pfx('image')} />}
      </div>
      <div className={pfx('info-container')}>
        <div className={pfx('info-name')}>
          <span>{name}</span>
        </div>
        <div className={pfx('info-discount')}>
          {!!discountPercentage && !!metadata.originalPrice && (
            <>
              <div className={pfx('info-discount-percent')}>
                <span>{`${discountPercentage}% Dcto.`}</span>
              </div>
              <div className={pfx('info-discount-original-price')}>
                <span>{toCurrency(metadata.originalPrice * quantity)}</span>
              </div>
            </>
          )}
        </div>
        <div className={pfx('info-totals')}>
          <div className={pfx('info-totals-count')}>
            <span>{formatQuantity(quantity)}</span>
          </div>
          <div className={pfx('info-totals-amount')}>
            <span>{toCurrency(total_amount_cents)}</span>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export * from './OrderLineItemSkeleton'
